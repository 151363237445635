<template>
  <div id="marker">
    <SiteNav></SiteNav>
    <section v-if="markerInformation === 'ERROR'">
      <h3>
        We're sorry, this marker doesn't exist yet. 
        Please click the top left icon to return to the overall view 
        and let us know how we can improve. Thanks!
      </h3>
    </section>
    <section v-else>
      <div class="col1">
        <div class="container">
          <h3 class="bottom-padding">{{ markerInformation.symbol }}</h3>
          <p class="bottom-padding">{{ markerInformation.name }}</p>
          <div class="current-wellth">
            <img src="@/assets/logo.svg" alt="logo" class="top-padding"/>
            <!-- change transition duration for vue-odometer here:
            ../../node_modules/odometer/themes/odometer-theme-minimal.css -->
            <h3 v-if="resultsManipulated.length">
              <vue-odometer
                :value="chartResult"
                format="(,ddd).d"
                animation="smooth"
                :duration="500"
              />
            </h3>
            <h3 v-else>--</h3>
            <h3 v-if="resultsManipulated.length" class="left-padding">({{ {val: chartValue, format: markerInformation.units, decimals: markerInformation.decimals} | formatValue }})</h3>
          </div>
          <h6 v-if="resultsManipulated.length" v-bind:class="lineColor(differenceValue)" class="bottom-padding top-padding"><span class="arrow" v-html="arrow(differenceValue)"></span>{{ {val: Math.abs(differenceValue), format: markerInformation.units, decimals: markerInformation.decimals} | formatValue }} ({{ percentDifference }}) <span class="rangeName" v-html="rangeNameSelection"></span></h6>
          <h6 v-else class="bottom-padding top-padding">Add a result to start tracking your wellth!</h6>
          <div class="big-chart">
            <v-chart
              :options="line"
              @updateAxisPointer="updateAxisPointer"
              autoresize
            />
          </div>
          <div class="range-picker">
            <button @click="changeRange('1D')"><h6 v-bind:class="{ 'button-selected': range == '1D' }">1D</h6></button>
            <button @click="changeRange('1W')"><h6 v-bind:class="{ 'button-selected': range == '1W' }">1W</h6></button>
            <button @click="changeRange('1M')"><h6 v-bind:class="{ 'button-selected': range == '1M' }">1M</h6></button>
            <button @click="changeRange('3M')"><h6 v-bind:class="{ 'button-selected': range == '3M' }">3M</h6></button>
            <button @click="changeRange('1Y')"><h6 v-bind:class="{ 'button-selected': range == '1Y' }">1Y</h6></button>
            <button @click="changeRange('5Y')"><h6 v-bind:class="{ 'button-selected': range == '5Y' }">5Y</h6></button>
            <button @click="changeRange('ALL')"><h6 v-bind:class="{ 'button-selected': range == 'ALL' }">ALL</h6></button>
          </div>
          <hr>
        </div>
        <div class="container">
          <h3>About</h3>
          <hr>
          <div class="marker-information">
            <p>{{ markerInformation.description }} {{ markerInformation.instructions }} {{ markerInformation.motivation }} <a :href="markerInformation.link" target="_blank">More...</a></p>
            <div class="info-grid">
              <div class="info-grid-item">
                <p class="titles">Category</p>
                <p>{{ markerInformation.category }} ({{ markerInformation.categorySymbol }})</p>
              </div>
              <!-- <div class="info-grid-item">
                <p class="titles">Symbol</p>
                <p>{{ markerInformation.symbol }}</p>
              </div> -->
              <!-- <div class="info-grid-item">
                <p class="titles">Added</p>
                <p>{{ markerInformation.yearAdded }}</p>
              </div>
              <div class="info-grid-item">
                <p class="titles">Improvement</p>
                <p>{{ markerInformation.improvementDirection }}</p>
              </div> -->
              <div class="info-grid-item">
                <p class="titles">Best Score (100)</p>
                <p>{{ { val: markerInformation.wellthMax, format: markerInformation.units, decimals: markerInformation.decimals} | formatValue }}</p>
              </div>
              <div class="info-grid-item">
                <p class="titles">Worst Score (0)</p>
                <p>{{ { val: markerInformation.wellthMin, format: markerInformation.units, decimals: markerInformation.decimals} | formatValue }}</p>
              </div>
              <div class="info-grid-item">
                <p class="titles">Total Users</p>
                <p>{{ markerInformation.totalUsers }}</p>
              </div>
              <div class="info-grid-item">
                <p class="titles">Total Results</p>
                <p>{{ markerInformation.totalResults }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container wide">
          <h3>Past Results</h3>
          <hr>
          <div class="marker-headers">
            <h5>Date</h5>
            <h5>Result</h5>
            <h5>Note</h5>
          </div>
          <div v-if="resultsManipulated.length">
            <div v-for="result in resultsManipulated" :key="result.id" class="post">
              <div @click="toggleEditModal(result)" class="marker-summary">
                <h5>{{ result.createdOn | formatDate }}</h5>
                 <!--adding star and PR: &#9733; PR -->
                <h5>{{ {val: result.value, format: markerInformation.units, decimals: markerInformation.decimals} | formatValue }} (<span v-bind:class="lineColor(result.changeFromPrevious)"><span class="arrow" v-html="arrow(result.changeFromPrevious)"></span>{{ {val: Math.abs(result.changeFromPrevious), format: markerInformation.units, decimals: markerInformation.decimals} | formatValue }}</span>)</h5>
                <!-- <h5 v-bind:class="lineColor(result.changeFromPrevious)"><span class="arrow" v-html="arrow(result.changeFromPrevious)"></span> {{ {val: Math.abs(result.changeFromPrevious), format: markerInformation.units, decimals: markerInformation.decimals} | formatValue }}</h5> -->
                <h5 v-if="result.note.length > 0">{{ result.note | trimLength }}</h5>
                <h5 v-else class="opaque">Add a note?</h5>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="no-results">Add a result to start tracking your wellth!</p>
          </div>
        </div>
        <div class="container narrow">
          <h3>Past Results</h3>
          <hr>
          <div class="marker-headers">
            <h5>Date</h5>
            <h5>Result</h5>
            <h5>Note</h5>
          </div>
          <div v-if="resultsManipulated.length">
            <div v-for="result in resultsManipulated" :key="result.id" class="post">
              <div @click="toggleEditModal(result)" class="marker-summary">
                <p>{{ result.createdOn | formatDate }}</p>
                 <!--adding star and PR: &#9733; PR -->
                <p>{{ {val: result.value, format: markerInformation.units, decimals: markerInformation.decimals} | formatValue }} <span v-bind:class="lineColor(result.changeFromPrevious)"><span class="arrow" v-html="arrow(result.changeFromPrevious)"></span></span></p>
                <!-- <h5 v-bind:class="lineColor(result.changeFromPrevious)"><span class="arrow" v-html="arrow(result.changeFromPrevious)"></span> {{ {val: Math.abs(result.changeFromPrevious), format: markerInformation.units, decimals: markerInformation.decimals} | formatValue }}</h5> -->
                <p v-if="result.note.length > 0">{{ result.note | trimLength }}</p>
                <p v-else class="opaque">Add a note?</p>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="no-results">Add a result to start tracking your wellth!</p>
          </div>
        </div>
        <div class="container info-panel-narrow">
          <h3>New Result</h3>
          <hr class="bottom-padding">
          <form @submit.prevent>
            <label for="date">Date</label>
            <input type='datetime-local' v-model.trim="result.date" :placeholder="result.date" :max="new Date()" id="date" />
            <label for="value">Value</label>
            <input v-model.trim="result.value" type="number" :placeholder="newValueFormat" id="value" />
            <label for="note">Note</label>
            <textarea v-model.trim="result.note" type="text" placeholder="Any notes?"></textarea>
            <button @click="createResult()" :disabled="result.date === '' || new Date(result.date) > new Date() || result.value === '' || result.value < 0 || result.date == null || result.value == null" class="button">Add</button>
          </form>
        </div>
        <!-- <hr> -->
        <footer>
          <p>
            &#169; 2021 Wellthmark. All rights reserved.
          </p>
          <p>
            Contact us at help@wellthmark.com
          </p>
          <p>
            <a href="https://forms.gle/UELeh1MX4RuWYZKQ9" target="_blank">Leave Feedback</a>
          </p>
          <p>
            <a href="https://forms.gle/4sxiLjPDLEaWK8LWA" target="_blank">Subscribe</a>
          </p>
        </footer>
      </div>
      <div class="col2">
        <div class="info-panel">
          <h3>New Result</h3>
          <hr class="bottom-padding">
          <form @submit.prevent>
            <label for="date">Date</label>
            <input type='datetime-local' v-model.trim="result.date" :placeholder="result.date" :max="new Date()" id="date" />
            <label for="value">Value</label>
            <input v-model.trim="result.value" type="number" :placeholder="newValueFormat" id="value" />
            <label for="note">Note</label>
            <textarea v-model.trim="result.note" type="text" placeholder="Any notes?"></textarea>
            <button @click="createResult()" :disabled="result.date === '' || new Date(result.date) > new Date() || result.value === '' || result.value < 0 || result.date == null || result.value == null" class="button">Add</button>
          </form>
        </div>
      </div>
    </section>
    <transition name="fade">
      <div v-if="showEditModal" class="e-modal">
        <div class="e-container">
          <form @submit.prevent>
            <label for="date">Date</label>
            <input type='datetime-local' v-model.trim="selectedResult.createdOn" :placeholder="selectedResult.createdOn" id="date" />
            <label for="value">Value</label>
            <input v-model.trim="selectedResult.value" type="number" :placeholder="selectedResult.value" id="value" />
            <label for="note">Note</label>
            <textarea v-model.trim="selectedResult.note" type="text" :placeholder="selectedResult.note"></textarea>
          </form>
          <div class="modal-buttons">
            <button @click="updateResult()" :disabled="selectedResult.createdOn === '' || new Date(selectedResult.createdOn) > new Date() || selectedResult.value === '' || selectedResult.value < 0 || selectedResult.createdOn == null || selectedResult.value == null" class="save"><h5>save</h5></button>
            <button @click="deleteResult()" class="delete"><h5>delete</h5></button>
            <button @click="closeEditModal()" class="cancel"><h5>cancel</h5></button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import debounce from 'lodash/debounce'
import VueOdometer from 'v-odometer/src'
import SiteNav from '@/components/SiteNav'

import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/dataset'

export default {
  components: {
    'v-chart': ECharts,
    'SiteNav': SiteNav,
    'vue-odometer': VueOdometer
  },
  data() {
    return {
      chartIndex: 0,
      range: 'ALL',
      result: {
        date: this.formDateFormat(new Date()),
        note: '',
        value: null
      },
      selectedResult: {
        createdOn: null,
        id: null,
        note: '',
        value: null
      },
      showEditModal: false
    }
  },
  computed: {
    ...mapState([
      'markers',
      'results',
      'userProfile'
    ]),
    markerSymbol() {
      return this.$route.params.markerSymbol.toUpperCase()
    },
    markerInformation() {
      if (this.markers.length > 0) {
        let currentMarker = this.markers.filter((marker) => {
          return marker.symbol === this.markerSymbol
        })
        if (currentMarker.length == 1) {
          let markerData = currentMarker[0]
          let wellthMin = 0
          let wellthMax = 0
          if (this.userProfile.gender == 'female') {
            wellthMin = markerData.wellthMinFemale
            wellthMax = markerData.wellthMaxFemale
          } else {
            wellthMin = markerData.wellthMinMale
            wellthMax = markerData.wellthMaxMale
          }
          return {
            ...markerData,
            wellthMin: wellthMin,
            wellthMax: wellthMax,
            yearAdded: moment(1000*markerData.createdOn.seconds).format('YYYY')
          }
        } else {
          return 'ERROR'
        }
      } else {
        return 'ERROR'
      }
    },
    resultsManipulated() {
      if (this.results.length > 0) {
        let resultSet = this.results.filter((result) => {
          return result.symbol === this.markerSymbol
        })
        return resultSet.map((result, index) => {
          let changeFromPrevious = 0
          if (index != resultSet.length - 1) {
            changeFromPrevious = result.value - resultSet[index + 1].value
          }
          let wellth = 0
          if (this.userProfile.gender == 'female') {
            wellth = parseFloat(Math.min(Math.max((100 * (result.value - this.markerInformation.wellthMinFemale) / (this.markerInformation.wellthMaxFemale - this.markerInformation.wellthMinFemale)),0),100).toFixed(this.markerInformation.decimals))
          } else {
            wellth = parseFloat(Math.min(Math.max((100 * (result.value - this.markerInformation.wellthMinMale) / (this.markerInformation.wellthMaxMale - this.markerInformation.wellthMinMale)),0),100).toFixed(this.markerInformation.decimals))
          }
          // add personalRecord calculation (new pr, current pr, etc.)
          // if (result.value  Math.min(...array1)) {
          //   newResult.personalRecord = 'New PR'
          // }
          return {
            ...result,
            changeFromPrevious: changeFromPrevious.toFixed(this.markerInformation.decimals),
            createdOn: result.createdOn.toDate(),
            wellth: wellth
          }
        })
      } else {
        return []
      }
    },
    firstResultDate() {
      if (this.resultsManipulated.length > 0) {
        return this.resultsManipulated[this.resultsManipulated.length - 1].createdOn
      } else {
        return moment().startOf('hour').subtract(23, 'hours').toDate()
      }
    },
    rangeInfo() {
      let allInfo = {}
      if (this.firstResultDate > moment().startOf('hour').subtract(47, 'hours').toDate()) {
        allInfo = {
          end: moment().startOf('hour').add(1, 'hours').toDate(),
          format: 'HH:mm',
          number: 15,
          unit: 'minutes'
        }
      } else if (this.firstResultDate > moment().startOf('hour').add(1, 'hours').subtract(2, 'weeks').toDate()) {
        allInfo = {
          end: moment().startOf('hour').add(1, 'hours').toDate(),
          format: 'MMM D HH:mm',
          number: 1,
          unit: 'hours'
        }
      } else if (this.firstResultDate > moment().startOf('day').add(1, 'days').subtract(2, 'months').toDate()) {
        allInfo = {
          end: moment().startOf('day').add(1, 'days').toDate(),
          format: 'MMM D HH:mm',
          number: 6,
          unit: 'hours'
        }
      } else if (this.firstResultDate > moment().startOf('day').add(1, 'days').subtract(6, 'months').toDate()) {
        allInfo = {
          end: moment().startOf('day').add(1, 'days').toDate(),
          format: 'MMM D, YYYY',
          number: 1,
          unit: 'days'
        }
      } else if (this.firstResultDate > moment().startOf('week').add(1, 'weeks').subtract(2, 'years').toDate()) {
        allInfo = {
          end: moment().startOf('week').add(1, 'weeks').toDate(),
          format: 'MMM D, YYYY',
          number: 1,
          unit: 'weeks'
        }
      } else {
        allInfo = {
          end: moment().startOf('month').add(1, 'months').toDate(),
          format: 'MMM YYYY',
          number: 1,
          unit: 'months'
        }
      }
      let addMinutes = 15 * Math.ceil(moment().minutes() / 15)
      switch(this.range) {
        case '1D':
          return {
            end: moment().startOf('hour').add(addMinutes, 'minutes').toDate(),
            format: 'HH:mm',
            name: 'Past Day',
            number: 15,
            start: moment().startOf('hour').add(addMinutes, 'minutes').subtract(24, 'hours').toDate(),
            unit: 'minutes'
          }
        case '1W':
          return {
            end: moment().startOf('hour').add(1, 'hours').toDate(),
            format: 'MMM D HH:mm',
            name: 'Past Week',
            number: 1,
            start: moment().startOf('hour').add(1, 'hours').subtract(1, 'weeks').toDate(),
            unit: 'hours'
          }
        case '1M':
          return {
            end: moment().startOf('day').add(1, 'days').toDate(),
            format: 'MMM D HH:mm',
            name: 'Past Month',
            number: 6,
            start: moment().startOf('day').add(1, 'days').subtract(1, 'months').toDate(),
            unit: 'hours'
          }
        case '3M':
          return {
            end: moment().startOf('day').add(1, 'days').toDate(),
            format: 'MMM D, YYYY',
            name: 'Past 3 Months',
            number: 1,
            start: moment().startOf('day').add(1, 'days').subtract(3, 'months').toDate(),
            unit: 'days'
          }
        case '1Y':
          return {
            end: moment().startOf('week').add(1, 'weeks').toDate(),
            format: 'MMM D, YYYY',
            name: 'Past Year',
            number: 1,
            start: moment().startOf('week').add(1, 'weeks').subtract(1, 'years').toDate(),
            unit: 'weeks'
          }
        case '5Y':
          return {
            end: moment().startOf('month').add(1, 'months').toDate(),
            format: 'MMM YYYY',
            name: 'Past 5 Years',
            number: 1,
            start: moment().startOf('month').add(1, 'months').subtract(5, 'years').toDate(),
            unit: 'months'
          }
        case 'ALL':
          return {
            end: allInfo.end,
            format: allInfo.format,
            name: 'All Time',
            number: allInfo.number,
            start: this.firstResultDate,
            unit: allInfo.unit
          }
        default:
          return {
            end: allInfo.end,
            format: allInfo.format,
            name: 'All Time',
            number: allInfo.number,
            start: this.firstResultDate,
            unit: allInfo.unit
          }
      }
    },
    rangeNameSelection () {
      if (this.chartIndex > 0) {
        return ''
      } else {
        return this.rangeInfo.name
      }
    },
    timelineData() {
      let now = this.rangeInfo.end
      let start = new Date(this.rangeInfo.start)
      let byPeriod = []
      let previousLength = 0
      let previousValue = 0
      let previousWellth = 0
      for (let d = new Date(now); d >= start; d = moment(d).subtract(this.rangeInfo.number, this.rangeInfo.unit).toDate()) {
        let previousResults = this.resultsManipulated.filter((point) => {
          return point.createdOn <= d
        })
        if (previousResults.length > 0 && previousResults.length != previousLength) {
          let latestResult = previousResults[0]
          byPeriod.push({
            t: new Date(moment(d).subtract(this.rangeInfo.number, this.rangeInfo.unit).toDate()),
            value: latestResult.value,
            wellth: latestResult.wellth,
          })
          previousLength = previousResults.length
          previousValue = latestResult.value
          previousWellth = latestResult.wellth
        } else {
          byPeriod.push({
            t: new Date(moment(d).subtract(this.rangeInfo.number, this.rangeInfo.unit).toDate()),
            value: previousValue,
            wellth: previousWellth
          })
        }
      }
      return byPeriod

      // if (this.resultsManipulated.length == 0) {
      //   return [[new Date(), 0], [this.rangeInfo.start, 0]]
      // } else if (this.resultsManipulated.length == 1) {
      //   let timeline = this.resultsManipulated.map(result => [
      //     moment(1000*result.createdOn.seconds).format('YYYY-MM-DD HH:MM:SS'),
      //     result.value
      //   ])
      //   timeline.push([
      //     moment().format('YYYY-MM-DD HH:MM:SS'),
      //     timeline[0][1]
      //   ])
      //   return timeline
      // } else {
      //   return this.resultsManipulated.map(result => [
      //     moment(1000*result.createdOn.seconds).format('YYYY-MM-DD HH:MM:SS'),
      //     result.value
      //   ])
      // }
    },
    lineData() {
      return this.timelineData.map((point) => [
        point.t,
        point.value
      ])
    },
    wellth100() {
      return this.timelineData.map((point) => [
        point.t,
        this.markerInformation.wellthMax
      ])
    },
    wellth0() {
      return this.timelineData.map((point) => [
        point.t,
        this.markerInformation.wellthMin
      ])
    },
    chartResults() {
      return this.timelineData.map((point) => {
        return {
          value: point.value,
          wellth: point.wellth
        }
      })
    },
    chartResult() {
      return this.chartResults[this.chartIndex].wellth.toFixed(this.markerInformation.decimals)
    },
    earliestResult () {
      return this.chartResults[this.chartResults.length - 1].wellth
    },
    difference() {
      return (this.chartResult - this.earliestResult).toFixed(this.markerInformation.decimals)
    },
    chartValue() {
      return this.chartResults[this.chartIndex].value
    },
    earliestValue () {
      return this.chartResults[this.chartResults.length - 1].value
    },
    differenceValue() {
      return (this.chartValue - this.earliestValue).toFixed(this.markerInformation.decimals)
    },
    percentDifference() {
      return Math.min((100 * this.differenceValue / this.earliestValue), 999.9).toFixed(1).toString() + '%'
    },
    // resultsFiltered() {
    //   return this.resultsManipulated.filter((result) => {
    //     return result.createdOn >= this.rangeInfo.start
    //   })
    // },
    newValueFormat() {
      if (this.markerInformation.units === 'Minutes') {
        return 'Seconds'
      } else if (this.markerInformation.units === 'Percent') {
        return '%'
      } else if (this.markerInformation.units === 'None') {
        return ''
      } else {
        return this.markerInformation.units
      }
    },
    line() {
      return {
        legend: {
          type: 'plain',
          icon: 'circle',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 15,
          show: true,
          left: 0,
          top: 0,
          padding: [5, 0, 5, 0],
          selected: {
            'Me': true,
            '100': false,
            '0': false,
            'Demographic': false,
            'Global': false
          },
          selectedMode: false,
          selectorLabel: {
            show: false,
            color: '#1FA482'
          },
          textStyle: {
            fontFamily: 'Playfair Display',
          }
        },
        tooltip: {
          show: false
        },
        grid: {
          show: false,
          left: 0,
          top: '15%',
          right: 0,
          bottom: 0
        },
        xAxis: {
          type: 'time',
          show: true,
          position: 'top',
          min (value) {
            return value.min - 0.05 * Math.abs(value.max - value.min)
          },
          max (value) {
            return value.max + 0.05 * Math.abs(value.max - value.min)
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisPointer: {
            show: true,
            type: 'line',
            snap: true,
            z: 99999,
            label: {
              show: true,
              formatter: (params) => {
                return moment(params.value).format(this.rangeInfo.format)
              },
              color: '#787f85',
              backgroundColor: 'transparent',
              fontFamily: 'Playfair Display'
            },
            lineStyle: {
                color: '#787f85'
            },
            triggerTooltip: true,
            value: 0,
          }
        },
        yAxis: {
          type: 'value',
          show: false,
          min (value) {
            if (value.min == value.max) {
              return value.min - 1
            } else {
              return value.min - 0.05 * Math.abs(value.max - value.min)
            }
          },
          max (value) {
            if (value.min == value.max) {
              return value.min + 1
            } else {
              return value.max
            }
          }
        },
        series: [
          {
            type: 'line',
            name: 'Me',
            symbol: 'circle',
            itemStyle: {
              color: this.chartLineColor(this.difference),
              borderWidth: 0,
              borderColor: 'white'
            },
            lineStyle: {
              color: this.chartLineColor(this.difference)
            },
            symbolSize: 1,
            showSymbol: true,
            data: this.lineData,
            smooth: 0,
            smoothMonotone: 'x'
          },
          {
            type: 'line',
            name: '100',
            symbol: 'circle',
            itemStyle: {
              color: '#1FA482',
              borderWidth: 0,
              borderColor: 'white'
            },
            lineStyle: {
              color: '#1FA482',
              type: 'dashed'
            },
            showSymbol: false,
            symbolSize: 0,
            data: this.wellth100
          },
          {
            type: 'line',
            name: '0',
            symbol: 'circle',
            itemStyle: {
              color: '#ef5777',
              borderWidth: 0,
              borderColor: 'white'
            },
            lineStyle: {
              color: '#ef5777',
              type: 'dashed'
            },
            showSymbol: false,
            symbolSize: 0,
            data: this.wellth0
          },
          {
            type: 'line',
            name: 'Demographic',
            symbol: 'none',
            itemStyle: {
              color: '#c3d6e2',
              borderWidth: 0,
              borderColor: 'white'
            }
          },
          {
            type: 'line',
            name: 'Global',
            symbol: 'none',
            itemStyle: {
              color: '#e3f2fa',
              borderWidth: 0,
              borderColor: 'white'
            }
          }
        ]
      }
    }
  },
  methods: {
    changeRange(range) {
      this.range = range
    },
    createResult() {
      this.$store.dispatch('createResult', {
        date: this.result.date,
        note: this.result.note,
        symbol: this.markerInformation.symbol,
        value: parseFloat(this.result.value)
      })
      this.result = {
        date: this.formDateFormat(new Date()),
        note: '',
        value: null
      }
    },
    deleteResult() {
      this.$store.dispatch('deleteResult', this.selectedResult.id)
      this.closeEditModal()
    },
    updateResult() {
      this.$store.dispatch('updateResult', {
        date: this.selectedResult.createdOn,
        id: this.selectedResult.id,
        note: this.selectedResult.note,
        value: this.selectedResult.value
      })
      this.closeEditModal()
    },
    toggleEditModal(result) {
      this.showEditModal = !this.showEditModal
      // if opening modal set selectedResult, else clear
      if (this.showEditModal) {
        Object.assign(this.selectedResult, result)
        this.selectedResult.createdOn = this.formDateFormat(this.selectedResult.createdOn)
      } else {
        this.selectedResult = {}
      }
    },
    closeEditModal() {
      this.selectedResult = {}
      this.showEditModal = false
    },
    arrow(difference) {
      if (difference > 0) {
        return '&#8593'
      } if (difference < 0) {
        return '&#8595'
      }
      return '&#8596;&#xfe0e'
    },
    lineColor(difference) {
      return {
        improving: (this.markerInformation.improvementDirection === 'Up' && difference > 0) || (this.markerInformation.improvementDirection === 'Down' && difference < 0),
        deteriorating: (this.markerInformation.improvementDirection === 'Up' && difference < 0) || (this.markerInformation.improvementDirection === 'Down' && difference > 0),
        consistent: false
      }
    },
    chartLineColor(difference) {
      if (difference > 0) {
        return '#1FA482'
      } else if (difference < 0) {
        return '#ef5777'
      } else {
        return '#787f85'
      }
    },
    formDateFormat(date) {
      return moment(date).format('YYYY-MM-DDTHH:mm')
    },
    updateAxisPointer: debounce(
      function (params) {
        let newIndex = params.dataIndex
        if (newIndex == null) {
          newIndex = 0
        }
        this.chartIndex = newIndex
      },
      500, // only fire if at least half second ellapsed between hovers
      {
        'leading': false,
        'maxWait': 500,
        'trailing': true
      } // fire only on the leading part of the batch
    )
  },
  filters: {
    formatDate(val) {
      if (!val) { return '-' }
      let date = val //.toDate()
      return moment(date).format('MMM D, YYYY')
    },
    trimLength(val) {
      if (val!= null) {
        if (val.length < 200) { return val }
        return `${val.substring(0, 200)}...`
      }
      return 'No Note'
    },
    formatValue(payload) {
      if (payload.format === 'Minutes') {
        return moment("1900-01-01 00:00:00").add(payload.val, 'Seconds').format("m:ss")
      } else if (payload.format === 'Percent') {
        return payload.val.toFixed(payload.decimals) + '%'
      } else if (payload.format === 'None') {
        return payload.val.toFixed(payload.decimals)
      } else {
        return payload.val.toFixed(payload.decimals) + ' ' + payload.format
      }
    }
  }
}
</script>

<style lang="scss" scoped>

// colors
$primary: #3D4D62;
$secondary: #7399C6;
$error: #ef5777;
$darkGreen: #1FA482;
$lightGreen: #5CD0B5;
$lightest: #e3f2fa;
$light: #c3d6e2;
$medium: #7399C6;
$dark: #3D4D62;
$white: #FFFFFF;
$deteriorating: #ef5777;
$improving: #1FA482;
$greyedOut: #787f85;

// fonts
$primaryFont: 'Playfair Display';
$myFontSize: 18px;
$myWidth: 680px;

.wide {
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.narrow {
  @media screen and (min-width: 800px) {
    display: none;
  }
}

section {
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 3.6rem;
  height: 90vh;
  width: 100%;
  padding: 1rem 5%;

  @media screen and (max-width: 800px) {
    // display: block;
    position: relative;
    height: auto;
  }
}

.col1 {
  flex: 70%;
  height: 100%;
  padding: 0rem;
  overflow: auto;
  min-width: 350px;
}

.current-wellth {
  height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
}

img {
  height: 2rem; //100%;
}

.col2 {
  flex: 30%;
  height: 100%;
  padding: 2rem 1rem;
  overflow: hidden;
  min-width: 250px;

  @media screen and (max-width: 800px) {
    display: none;
  }
}

.container {
  padding: 1rem;
}

.info-panel-narrow {
  @media screen and (min-width: 800px) {
    display: none;
  }

  textarea {
    height: 10vh
  }

  button {
    background: $white;
    border: 0 solid $primary;
    padding: 0.5rem 1rem;
    border-radius: 7px;
    box-shadow: 0 0 0 0 rgba($dark, 0.5);
    cursor: pointer;
    width: 100%;

    &:hover {
      background: $lightest;
    }

    &:disabled {
      opacity: 0.8;

      &:hover {
        background: rgba($primary, 0.2);
      }
    }
  }
}

.info-panel {
  padding: 2rem;
  height: 100%;
  box-shadow: 0 0 20px 0 rgba($dark, 0.5);
  overflow: hidden;

  textarea {
    height: 20vh
  }

  button {
    background: $white;
    border: 0 solid $primary;
    padding: 0.5rem 1rem;
    border-radius: 7px;
    box-shadow: 0 0 0 0 rgba($dark, 0.5);
    cursor: pointer;
    width: 100%;

    &:hover {
      background: $lightest;
    }

    &:disabled {
      opacity: 0.8;

      &:hover {
        background: rgba($primary, 0.2);
      }
    }
  }
}

.big-chart {
  height: 40vh;
  @media screen and (max-width: 800px) {
    height: 30vh;
  }
}

.echarts {
  width: 100%;
  height: 100%;
}

.shadow {
  box-shadow: 0 0 20px 0 rgba($dark, 0.5);
}

.rangeName {
  color: $greyedOut;
}

.post {
  border: 1px solid #e6ecf0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  font-size: 14px;
  padding: 0.75rem 0;
  background: $white;
  // max-height: 10vh;

  &:hover {
    background: #f5f8fa;
    cursor: pointer;
  }

  &:last-of-type {
    border-bottom: 1px solid #e6ecf0;
  }

  h5 {
    margin: 0 0 0.5rem;
  }

  // span {
  //   display: block;
  //   font-style: italic;
  //   font-size: 12px;
  //   margin-bottom: 0.5rem;
  //   color: darken($light, 25%);
  // }

  p {
    margin: 0;
  }

  .marker-summary {
    display: flex;
    flex-direction: row;
  }
}

.info-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-items: left;
  padding: 1rem 0 0 0;

  .info-grid-item {
    // flex: 100px;
    width: 120px;
    padding: 0 0 0.5rem 0;

    p {
      font-size: 90%;
      font-weight: 100;
      opacity: 0.7;
    }

    .titles {
      font-weight: 900;
      opacity: 1;
    }
  }
}

.marker-summary {
  display: flex;
  flex-direction: row;
  padding: 0;

  h5 {
    flex: 1 1 0;
  }

  * {
    flex: 1 1 0;
    padding: 0 0.5rem 0 0;
  }
}

.marker-headers {
  padding: 0.5rem 0 0rem 0;
  display: flex;
  flex-direction: row;

  h5 {
    flex: 1 1 0;
  }
}

.improving {
  color: $improving;
  padding: 0;
}

.deteriorating {
  color: $deteriorating;
  padding: 0;
}

.arrow {
  font-family: Helvetica;
  font-size: 1rem;
  padding: 0;
}

.button-selected {
  color: $medium;
}

.modal-buttons {
  display: flex;
  padding: 1rem;

  button {
    flex: 1 1 0;
    background: $white;
    border: 0 solid $primary;
    padding: 0.5rem 1rem;
    border-radius: 7px;
    box-shadow: 0 0 0 0 rgba($dark, 0.5);
    cursor: pointer;

    &:hover {
      background: $lightest;
    }
  
    &:disabled {
      opacity: 0.5;
  
      &:hover {
        background: $primary;
      }
    }
  }
}

.e-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($dark, 0.4);
  z-index: 999;
  padding: 10rem;

  .e-container {
    position: fixed;
    max-width: 600px;
    top: 2vh;
    bottom: 2vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 3px;
    background: $white;
    box-shadow: 0 0 20px 0 rgba($dark, 0.5);
    overflow: auto;

    .close {
      position: absolute;
      top: 5px;
      right: 10px;
      padding: 5px;
    }

    .post {
      border: 0;
      border-bottom: 1px solid #e6ecf0;

      &:hover {
        background: $white;
      }
    }
  }
}

.range-picker {
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;

  button {
    height: 100%;
    width: 100%;
    background: $white;
    border: 0 solid $primary;
    padding: 0.5rem 0rem;
    border-radius: 7px;
    box-shadow: 0 0 0 0 rgba($dark, 0.5);
    cursor: pointer;
  
    &:hover {
      * {
        color: $medium;
      }
    }
  }
}

.marker-headers {
  display: flex;
  flex-direction: row;
}

.no-results {
  border: 1px solid #e6ecf0;
  background: $white;
  text-align: center;
  padding: 100px 1rem;
}

.c-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($dark, 0.4);
  z-index: 999;

  .c-container {
    position: relative;
    max-width: 600px;
    margin: 30vh auto 0;
    padding: 2rem;
    border-radius: 3px;
    background: $white;
    box-shadow: 0 0 20px 0 rgba($dark, 0.5);

    a {
      position: absolute;
      top: 5px;
      right: 10px;
      padding: 5px;
    }

    .button {
      margin-top: 1rem;
    }
  }
}

.p-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($dark, 0.4);
  z-index: 999;

  .p-container {
    position: fixed;
    max-width: 600px;
    top: 2vh;
    bottom: 2vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 3px;
    background: $white;
    box-shadow: 0 0 20px 0 rgba($dark, 0.5);
    overflow: auto;

    .close {
      position: absolute;
      top: 5px;
      right: 10px;
      padding: 5px;
    }

    .post {
      border: 0;
      border-bottom: 1px solid #e6ecf0;

      &:hover {
        background: $white;
      }
    }
  }
}

.bottom-padding {
  padding: 0 0 0.25rem 0;
}

.top-padding {
  padding: 0.5rem 0 0 0;
}

.opaque {
  opacity: 0.6;
}

.left-padding {
  padding: 0 0 0 0.5rem;
}

hr {
  opacity: 0.3;
}

a {
  opacity: 0.7;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vh;

  * {
    margin: auto;
    padding: 1vh;
    color: $greyedOut;

    a {
      // color: $secondary;
      opacity: 1;
      // text-decoration: underline;
      // padding: 0.5rem;

      &:hover {
        // background-color: #000;
        color: $primary;
      }
    }
  }
}

</style>
